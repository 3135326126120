import { Modal } from 'antd';
import { SmileOutlined } from '@ant-design/icons';
import bellSound from '../bell.wav';

const soundAlert = () => {

    const audio = new Audio(bellSound);

    Modal.info({
        title: 'Sound Alert',
        content: "Please click the 'OK' button to hide this alert; otherwise, this alert will not be dismissed.",
        icon: <SmileOutlined />,
        onOk() { audio.play() },
    });
}

export default soundAlert