export const SERVER_API_URL = 'https://easysell.pk' // Production

export const SERVER_WEBSOCKET_HOST = 'socket.easysell.pk';
export const SERVER_WEBSOCKET_PORT = 6002;
export const SERVER_PUSHER_APP_KEY = '0770e18800d7fd0a59e5';
export const SERVER_PUSHERAPP_ID = '1661354';
export const SERVER_PUSHER_APP_SECRET = 'ac8dfa68fcf2f86494b0';
export const SERVER_PUSHER_APP_CLUSTER = 'ap1';
export const SERVER_WEBSOCKET_URL = `wss://${SERVER_WEBSOCKET_HOST}:${SERVER_WEBSOCKET_PORT}/socket`;

export const PAGINATION_OPTIONS = {
    defaultPageSize: 50,
    showSizeChanger: true,
    pageSizeOptions: ['10', '25', '50', '100', '200', '500', '1000']
}

export const CLIENT_ID = '5';
export const CLIENT_SECRET = 'Pi43bKhi7uNnKaVoJTAqkxDsKByXHbRetztRv4FB';