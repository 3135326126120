import { useEffect, useState } from "react";
import { Button, Card, Col, Form, Input, Row, Select } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import useWebSocket from "../../utility/use-websocket";
import { getUser } from "../../data";
import soundAlert from "../../layout/sound_alert";

const { Option } = Select

export default function UserBranches() {

    let history = useHistory();
    let location = useLocation();

    const [branches, setBranches] = useState([])

    const { socketHandler } = useWebSocket()

    let { from } = location.state || { from: { pathname: "/" } };

    const onFinish = async ({ branch }) => {

        localStorage.setItem('posBranchId', branch)
        const branchId = localStorage.getItem('posBranchId')

        if (branchId !== null) {
            history.replace(from);
            soundAlert()
        }
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        // branches
        socketHandler('branches', null, (response) => {
            loadUser(response)
        });


    }, [])

    const loadUser = async (data) => {
        const response = await getUser();
        if (response) {
            const user_branches = (response.user_branches).map(i => i.branch_id)
            const filteredArray = data.filter(item => user_branches.includes(item.id));
            setBranches(filteredArray)
        }
    }

    return (
        <>
            <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '70px', marginBottom: '70px' }} >
                <Col span={3}></Col>
                <Col span={18}>
                    <Row>
                        <Col span={8} />
                        <Col span={8}>
                            <Card title="Select Your Branch" bordered={false} style={{ width: '100%', marginTop: 50 }}>
                                <Form
                                    //{...layout}
                                    layout={'vertical'}
                                    name="basic"
                                    initialValues={{
                                        remember: true,
                                    }}
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                >
                                    <Form.Item
                                        label="Branches"
                                        name="branch"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Branch is required',
                                            },
                                        ]}
                                    >
                                        <Select placeholder="Select Branch" allowClear showSearch
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {
                                                branches.map(({ id, name }) => (
                                                    <Option key={id} value={id}>{name}</Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>

                                    <Form.Item>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Button type="ghost" danger onClick={() => history.push({ pathname: 'login' })}>
                                                Back to login
                                            </Button>
                                            <Button type="primary" htmlType="submit">
                                                Submit
                                            </Button>
                                        </div>
                                    </Form.Item>

                                </Form>
                            </Card>
                        </Col>
                        <Col span={8} />
                    </Row>

                </Col>
                <Col span={3} />
            </Row>
        </>
    )
}