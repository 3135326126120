// import useAsyncStorageState from './use-async-storage';
import { SERVER_WEBSOCKET_URL, SERVER_PUSHER_APP_KEY } from '../constants';

const useWebSocket = () => {

    const store_id = localStorage.getItem('store_id')
    // const [userToken] = useAsyncStorageState('userToken');

    function socketHandler(endpoint, data, callback) {

        const pusherAppKey = SERVER_PUSHER_APP_KEY;
        const url = SERVER_WEBSOCKET_URL;
        const socket = new WebSocket(`${url}/${endpoint}?appKey=${pusherAppKey}`);

        socket.onopen = () => {
            data = { ...data, store_id: store_id }
            socket.send(JSON.stringify(data)); // send a message
            // console.log('Message sent', data);
        };

        socket.onmessage = (e) => {
            let data = e.data.replace(/\\/g, '');
            if (data != "") {
                callback(JSON.parse(data)?.payload)
            }
        };
    }

    return { socketHandler };
}

export default useWebSocket;