import React from 'react';
import { Layout } from 'antd';

const { Content, Footer } = Layout;

export default function Auth({ children }) {
    const currentYear = new Date().getFullYear();
    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Layout className="site-layout" style={{ padding: 0, backgroundColor: '#365271' }}>
                <Content style={{ margin: '0 16px' }}>
                    <div className="site-layout" style={{ padding: 24, minHeight: 360 }}>
                        {children}
                    </div>
                </Content>
                <Footer style={{ textAlign: 'center' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p>Copyright © {currentYear} easysell.pk</p>
                        <p>Powered by ohadtech.com</p>
                    </div>
                </Footer>
            </Layout>
        </Layout>
    );
}