import React from 'react';
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";

import AuthLayout from '../layout/auth';
import MainLayout from '../layout';
import Login from '../pages/auth/login';
import Dashboard from '../pages/dashboard';
import UserBranches from '../pages/auth/branches';


const AuthRedirect = ({ destination }) => {
    return <Route render={(props) => (<Redirect to={{ pathname: destination, state: { from: props.location } }} />)} />;
}

const wrapper = (Layout, Component) => {

    let accessToken = localStorage.getItem('token');
    let branch_id = localStorage.getItem('posBranchId');

    const redirect = <AuthRedirect destination={'/login'}></AuthRedirect>

    if (accessToken == null) return redirect;
    if (branch_id == null) return <AuthRedirect destination={'/branches'}></AuthRedirect>

    return (
        <Layout>
            <Component />
        </Layout>
    )
}

export default function Navigation() {
    return (
        <Router>
            <Route exact path={`/login`} component={() => <AuthLayout><Login /></AuthLayout>} />
            <Route exact path={`/branches`} component={() => <AuthLayout><UserBranches /></AuthLayout>} />
            <Route exact path={`/`} component={() => wrapper(MainLayout, Dashboard)} />
        </Router>
    );
}