import { ajaxService, loadState, urlService } from "../services";
import { loadResource, Response } from "../utility";

const branchDependency = (path) => { return ajaxService.getBranchDependencyUrl(path) }

export async function getOrders(refresh = false) {
    ajaxService.setDependency()
    if (refresh) {
        return await loadResource(branchDependency(urlService.orders), 'orders');
    } else {
        let orders = await loadState('orders');

        if (orders == null) {
            return await loadResource(branchDependency(urlService.orders), 'orders');
        } else {
            return Response(orders);
        }
    }
}

export async function updateStatus(id, data) {
    ajaxService.removeDependency()
    return ajaxService.put(urlService.status + '/' + id, data).then(response => {
        return Response(response.data);
    })
}