import { Link, useHistory } from "react-router-dom";
import { Avatar, Dropdown, Layout, Menu } from 'antd';
import { LoginOutlined, UserOutlined, MobileOutlined } from '@ant-design/icons';

const { Header } = Layout;

export default function MainHeader() {

    let history = useHistory();

    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('posBranchId');
        history.push({ pathname: '/' })
    }

    const profile = (
        <Menu style={{ padding: '10px' }}>
            {/* <Menu.Item key="1" icon={<UserOutlined />}><Link to={'/profile'}>Profile</Link></Menu.Item> */}
            <Menu.Item key="2" onClick={logout} icon={<LoginOutlined />}>Logout</Menu.Item>
        </Menu>
    )

    return (
        <Header>
            <Menu mode="horizontal" style={{ background: '#003366', color: '#FFF' }} >

                <Menu.Item key="3" icon={<MobileOutlined />}><Link to={'/'}>Kitchen</Link></Menu.Item>
                <Menu.Item key="7">
                    <Dropdown overlay={profile} placement="bottom">
                        <a><Avatar icon={<UserOutlined />} /></a>
                    </Dropdown>
                </Menu.Item>
            </Menu>
        </Header>
    );
}