import { useState, useEffect } from 'react';
import { Card, Row, Col, Button, notification, Tag, Dropdown, Menu, Space, Typography, Divider, Empty } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { getOrders, updateStatus } from '../data';
import useWebSocket from '../utility/use-websocket';
import OrderStatus from '../constant/orderStatus';
import Echo from "laravel-echo"


import { SERVER_PUSHER_APP_CLUSTER, SERVER_PUSHER_APP_KEY, SERVER_WEBSOCKET_HOST, SERVER_WEBSOCKET_PORT } from '../constants';


import bellSound from '../bell.wav';

const { Text } = Typography

export default function Dashboard() {

  const { socketHandler } = useWebSocket()

  const [forceRefresh, setForceRefresh] = useState(true);

  const [orders, setOrders] = useState([]);
  const [products, setProducts] = useState([]);

  const initialize = async () => {
    const response = await getOrders(forceRefresh);
    if (response) {
      const data = response.sort((a, b) => a.itemM > b.itemM ? 1 : -1)
      setOrders(data.filter(order => order.status === OrderStatus.PROCESSING || order.status === OrderStatus.IN_QUEUE));
      setForceRefresh(false);
    }
  };

  useEffect(() => {
    if (forceRefresh) {
      initialize();
      // Products
      socketHandler('products', null, (response) => {
        setProducts(response)
      });

    }
  }, [forceRefresh, products]);

  // Brodcast

  const broadcaster = async () => {

    const echo = new Echo({
      broadcaster: 'pusher',
      key: SERVER_PUSHER_APP_KEY,
      cluster: SERVER_PUSHER_APP_CLUSTER,
      wsHost: SERVER_WEBSOCKET_HOST,
      wsPort: SERVER_WEBSOCKET_PORT,
      disableStats: true,
      encrypted: true,
      forceTLS: true,
    });

    echo.channel('store').listen('OrderChanged', ({ order }) => {

      const branch_id = localStorage.getItem('posBranchId');
      const store_id = localStorage.getItem('store_id');

      if (order.store_id == store_id && branch_id == branch_id && order.status == 'In Queue') {
        setOrders([...orders, order]);
        const audio = new Audio(bellSound);
        audio.play()
      }
    })
  }
  useEffect(() => {
    broadcaster()
  }, [])

  // End Brodcast

  const handleStatusChange = async (id, status) => {
    const newData = orders.map((item) =>
      item.id === id ? { ...item, status: status } : item
    );

    notification.open({ message: 'Order status has been updated', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> });
    setOrders(newData);

    await updateStatus(id, { status: status });

    setTimeout(async () => {
      setForceRefresh(true)
    }, 10000); // 10 second

  }

  const renderCombo = (row) => {
    const product = products.find(i => i.id === row.product_id)
    if (product) {

      const comboProducts = product.productCombo;

      if (Object.keys(comboProducts).length > 0) {
        return (
          comboProducts.map(i => {
            let p = products.find(obj => obj.id === i.item_id)
            return (
              <Row style={{ marginLeft: 20 }}>
                <Col span={2}></Col>
                <Col span={16}>
                  <Text style={{ color: '#00CC66' }}>{p ? p.name : null}</Text>
                </Col>
                <Col span={4}>
                  <Text style={{ color: '#00CC66' }}>{i.quantity}</Text>
                </Col>
              </Row>
            )
          })
        )
      }
    }
  }

  const renderAddons = (item) => {
    if (item.additions) {

      return item.additions.map((child, index) => {


        return (
          <Row
            gutter={16}
            // style={{ background: item.is_latest ? 'lightGreen' : '#fff', marginBottom: 10, width: 330 }}
            key={`row-${index}`}
          >
            <Col span={3} style={{ textAlign: 'center' }}></Col>
            <Col span={18}>
              <div style={{ whiteSpace: 'pre-wrap' }}>{child.title} {child.item}</div>
            </Col>
            <Col span={3}>{item.quantity}</Col>
          </Row>
        )


      })

    }
  }

  const renderData = () => {
    if (orders.length > 0) {
      return (
        <div className="scrollmenu">
          {orders.map((order) => (
            <Card
              key={order.id}
              className="kitchen_card"
              title={order.order_number}
              headStyle={{ fontSize: '17px' }}
              extra={
                <Space>
                  <Tag color={order.status === OrderStatus.READY ? '#00FF00' : (order.status === OrderStatus.IN_QUEUE ? 'darkRed' : 'darkOrange')} style={{ fontSize: '17px' }}>
                    {order.status}
                  </Tag>
                  <Dropdown
                    overlay={
                      <Menu>
                        {Object.values(OrderStatus).map((status) => (
                          <Menu.Item onClick={(e) => handleStatusChange(order.id, status)} key={status}>
                            {status}
                          </Menu.Item>
                        ))}
                      </Menu>
                    }
                    placement="bottom"
                  >
                    <Button type="primary">Status</Button>
                  </Dropdown>
                </Space>
              }
            >
              <Row style={{ marginBottom: 10, fontWeight: 'bold' }}>
                <Col span={3}>S.No</Col>
                <Col span={17} style={{ marginLeft: 4 }}>Items</Col>
                <Col span={3}>Qty</Col>
              </Row>
              <div style={{ width: '107%', overflow: 'auto', height: 380 }}>
                {order.order_items.map((item, index) => (
                  <>
                    <Row
                      gutter={16}
                      style={{ background: item.is_latest ? 'lightGreen' : '#fff', marginBottom: 10, width: 330 }}
                      key={`row-${index}`}
                    >
                      <Col span={3} style={{ textAlign: 'center' }}>{index + 1}</Col>
                      <Col span={18}>
                        <div style={{ whiteSpace: 'pre-wrap' }}>{item.product_name}</div>
                      </Col>
                      <Col span={3}>{item.quantity}</Col>
                    </Row>
                    {renderAddons(item)}
                    {renderCombo(item)}
                    <Divider style={{ margin: 10 }} />
                  </>
                ))}
              </div>
            </Card >
          ))}
        </div >
      );
    } else {
      return (
        <div className="kitchen-screen">
          <Empty description="No orders currently placed." />
        </div>
      )
    }
  }

  return (
    <>
      {renderData()}
    </>
  );
}